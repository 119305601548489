import { t } from '../i18n';

const messages = {
  required: t('errors:required'),
  numberRequired: t('errors:numberRequired'),
  maxLength: t('errors:maxLength'),
  minLength: t('errors:minLength'),
  orgNameLength: t('errors:orgNameLength'),
  orgNameSupportedChars: t('errors:orgNameSupportedChars'),
  mobile: t('errors:mobile'),
  mobilePhone: t('errors:mobilePhone'),
  email: t('errors:email'),
  multipleEmail: t('errors:multipleEmail'),
  name: t('errors:name'),
  cutoffTimeEarlierThanEwaAndTPOCutoffTime: t(
    'errors:cutoffTimeEarlierThanEwaCutoffTime',
  ),
  cutoffDayOffsetEarlierThanEwaAndTPOCutoffDayOffset: t(
    'errors:cutoffDayOffsetEarlierThanEwaAndTPOCutoffDayOffset',
  ),
  businessDayStartTimeEarlierThanEwaAndTPOCutoffTime: t(
    'errors:businessDayStartTimeEarlierThanEwaAndTPOCutoffTime',
  ),
  mustBeANumber: t('errors:mustBeANumber'),
  workerOfferPercentTooHigh: t('errors:workerOfferPercentTooHigh'),
  positiveNumbersOnly: t('errors:positiveNumbersOnly'),
  minPercent: t('errors:minPercent'),
  maxPercent: t('errors:maxPercent'),
  maxTotal: t('errors:maxTotal'),
  minAmount: t('errors:minAmount'),
  maxAmount: t('errors:maxAmount'),
  password: t('errors:password'),
  recoverFundsLessThanBalance: t('treasury:recoverFunds.amountError'),
  mustBeAmount: t('errors:mustBeAmount'),
  mustBeFormattedAmount: t('errors:mustBeFormattedAmount'),
  mustBePercent: t('errors:mustBePercent'),
  requiredEmails: t('errors:requiredEmails'),
  mustBeTime: t('errors:mustBeTime'),
  ddText: t('errors:ddText.error'),
  nonEmpty: t('errors:nonEmptyFields.error'),
  mustBeLetterOrNumber: t('errors:mustBeLetterOrNumber'),
  mustBeValidRoutingNumber: t('errors:routingNumberError'),
  mustBeValidAccountNumber: t('errors:accountNumberError'),
  address: t('errors:addressError'),
  addressUnit: t('errors:addressUnitError'),
  city: t('errors:cityError'),
  zipCode: t('errors:zipCodeError'),
  ssn: t('errors:ssnError'),
  payrollId: t('errors:payrollIdError'),
  namePaycard: t('errors:nameError'),
  proxyNumber: t('errors:proxyNumberError'),
  dateStandard: t('errors:dateStandardError'),
  errorLettersDigitsAndDash: t('errors:errorLettersDigitsAndDash'),
};

export default messages;
